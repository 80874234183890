import React from 'react';
import Courses from '../components/courses';
import PageBanner from '../components/pagebanner';
import SEO from '../components/seo';
import Layout from '../layouts/main';

const CoursesPage = () => {
    return(
        <Layout>
            <SEO title="Courses" />
            <PageBanner title="All Courses" />
            <div className="section topcourses">
                <div  className="innerwrap">
                    <div className="row">
                        <div className="grid-12">
                            <Courses/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CoursesPage;